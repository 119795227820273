.landheader {
  background: url(./img/fon.png);
  background-size: cover;
  background-position-x: center;
}
.landHeadCont {
  padding-bottom: 7.5vw;
}
.headLogo {
  display: flex;
  align-items: center;
}
.landTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1vw 0;
}
.click {
  width: 14vw;
}
.vidik {
  animation: rotati 5s infinite linear;
  cursor: pointer;
}
@keyframes rotati {
  0% {
    rotate: 1;
  }
  50% {
    scale: 1.05;
  }
  100% {
    scale: 1;
  }
}
.clicki {
  width: 8vw;
  margin-top: 0.5vw;
}
.vidik {
  position: absolute;
  right: 21vw;
  top: 30vw;
}
.vidik img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.landheader {
  padding: 0 3vw;
}
.rightTab {
  display: flex;
  align-items: center;
}
.pluses {
  display: flex;
}
.headLogo img {
  width: 8vw;
  margin-right: 1.5vw;
}
.lineInv {
  display: flex;
  align-items: center;
  margin-top: 1vw;
}
.lineInv span {
  color: #fff;
  margin-left: 1vw;
  font-weight: 700;
}
.pluses {
  border-radius: 1vw 0px 0px 1vw;
  padding: 1vw 1.5vw;
  background: rgba(78, 78, 78, 0.31);
  margin: 2vw 0;
  width: 53.1vw;
}
.pluses div {
  margin-right: 5vw;
}
.pluses b {
  color: #fff;
  font-family: "NEXT ART";
  font-size: 3.3vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.pluses p {
  color: #fff;
  font-family: "NEXT ART";
  font-size: 1.8vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.mainButt {
  margin-top: 4vw;
  display: flex;
  align-items: center;
}
.mainButt .button {
  border-radius: 6vw;
  background: linear-gradient(
      107deg,
      rgba(255, 255, 255, 0.1) 48.51%,
      rgba(255, 255, 255, 0) 49.21%
    ),
    #488255;
  margin-right: 1.5vw;
  box-shadow: 0px 15px 50px 0px rgba(93, 148, 105, 0.25);
  color: #fff;
  padding: 1.5vw 2.5vw;
  width: fit-content;
  font-size: 1vw;
  font-style: normal;
  font-weight: 600;
}
.pluses span {
  color: #95a393;
  font-size: 1vw;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 30px */
}
.mainButt p {
  background: linear-gradient(
    270deg,
    #c3b1b1 0.06%,
    rgba(195, 177, 177, 0.5) 101.59%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 500;
}
.lineInv img {
  margin-right: 1vw;
  width: 15vw;
}
.lineInv p {
  color: #83d294;

  font-size: 2vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.headLogo p {
  color: #c2afaf;

  font-size: 1vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.headLogo span {
  color: #fff;
}
.landTop .button {
  border-radius: 3vw;
  padding: 1vw 2vw;
  margin-right: 2vw;
  color: #fff;
  font-size: 0.7vw;
  background: linear-gradient(
      107deg,
      rgba(255, 255, 255, 0.2) 48.51%,
      rgba(255, 255, 255, 0) 49.21%
    ),
    #488255;
  box-shadow: 0px 8px 30px 0px rgba(93, 148, 105, 0.25);
}
.rightTab p {
  color: #fff;

  font-size: 0.7vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-right: 0.5vw;
}
.rightTab img {
  width: 1.5vw;
  margin-right: 0.5vw;
}
.rightTab a {
  color: #fff;
  text-decoration: none;
  font-size: 1.1vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.tels {
  margin-left: 2vw;
}
.leftHeadLand h2 {
  background: linear-gradient(
    180deg,
    #83d294 31.23%,
    rgba(131, 210, 148, 0) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 2vw;
  font-size: 4vw;
  font-style: normal;
  font-weight: 500;
  font-family: "NEXT ART";
  line-height: normal;
  text-transform: uppercase;
}
.leftHeadLand h1 {
  color: #fff;
  line-height: 8vw;
  font-size: 5.5vw;
  font-style: normal;
  font-family: "NEXT ART";
  font-weight: 400;
  margin-left: 0.3vw;
  text-transform: uppercase;
}
.leftHeadLand h3 {
  color: #fff;

  font-size: 7vw;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 110.4px */
  text-transform: uppercase;
}
@media screen and (max-width: 640px) {
  .headLogo img {
    width: 14vw;
    margin-right: 2.5vw;
  }
  .headLogo div {
    position: absolute;
    top: 15vw;
    display: flex;
    border-radius: 2vw;
    padding: 2vw 3vw;
    background: rgba(84, 79, 75, 0.6);
    box-shadow: 0px 4px 22.1px 0px rgba(0, 0, 0, 0.25);
  }
  .headLogo p {
    color: #c2afaf;
    font-size: 4vw;
    margin-right: 1vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .rightTab img {
    width: 5.5vw;
    margin-right: 1.5vw;
  }
  .rightTab a {
    color: #fff;
    text-decoration: none;
    font-size: 3.5vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .leftHeadLand h1 {
    color: #fff;
    line-height: 10vw;
    margin-top: 30vw;
    font-size: 10.5vw;
    text-align: left;
    font-style: normal;
    font-family: "NEXT ART";
    font-weight: 400;
    text-transform: uppercase;
  }
  .lineInv {
    display: flex;
    align-items: center;
    margin-top: 6vw;
  }
  .pluses {
    border-radius: 3vw;
    padding: 5vw;
    background: rgba(78, 78, 78, 0.31);
    margin: 2vw 0;
    margin-top: 130vw;
    width: 94vw;
  }
  .landHeadCont {
    padding-bottom: 28.5vw;
  }
  .mainButt {
    margin-top: 10vw;
  }
  .click {
    width: 41vw;
  }
  .clicki {
    width: 22vw;
    margin-top: 1.5vw;
  }
  .vidik {
    position: absolute;
    right: 50vw;
    top: 142vw;
  }
  .pluses p {
    display: flex;
    flex-direction: column;
    font-size: 4vw;
  }
  .pluses div {
    margin-right: 0;
    margin: 0 2.5vw;
  }
  .mainButt .button {
    border-radius: 11vw;
    background: linear-gradient(
        107deg,
        rgba(255, 255, 255, 0.1) 48.51%,
        rgba(255, 255, 255, 0) 49.21%
      ),
      #488255;
    margin-right: 0;
    box-shadow: 0px 15px 50px 0px rgba(93, 148, 105, 0.25);
    color: #fff;
    padding: 7.5vw 15.5vw;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 4vw;
    font-style: normal;
    font-weight: 600;
  }
  .mainButt p {
    background: linear-gradient(
      270deg,
      #c3b1b1 0.06%,
      rgba(195, 177, 177, 0.5) 101.59%
    );
    background-clip: text;
    text-align: center;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 4.2vw;
    font-style: normal;
    margin-bottom: 5vw;
    font-weight: 500;
  }
  .mainButt {
    flex-direction: column-reverse;
  }
  .pluses span {
    color: #95a393;
    font-size: 3vw;
    display: block;
    font-style: normal;
    font-weight: 500;
    margin-top: 3vw;
    line-height: 150%;
  }
  .pluses b {
    color: #fff;
    font-family: "NEXT ART";
    font-size: 8.3vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }
  .lineInv p {
    color: #83d294;
    font-size: 4vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .lineInv img {
    margin-right: 4vw;
    width: 33vw;
  }
  .leftHeadLand h3 {
    background: linear-gradient(0deg, #fff 23.44%, rgba(201, 201, 201, 0) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 12vw;
    font-style: normal;
    margin-top: 2vw;
    font-weight: 500;
    line-height: 100%;
    text-transform: uppercase;
  }
  .leftHeadLand h2 {
    background: linear-gradient(
      180deg,
      #83d294 31.23%,
      rgba(131, 210, 148, 0) 100%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: 13vw;
    font-size: 10.5vw;
    font-style: normal;
    font-weight: 500;
    font-family: "NEXT ART";
    line-height: normal;
    text-transform: uppercase;
  }
  .rightTab p {
    color: #fff;
    font-size: 2.2vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    margin-right: 1.5vw;
  }
  .headLogo span {
    font-size: 4vw;
  }
  .landTop .button {
    display: none;
  }
  .landheader {
    background: url(./img/mobfon.png);
    background-repeat: no-repeat;
    background-size: 100%;
  }
}

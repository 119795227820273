.secondBl {
  background: url(./img/fon.png);
  background-size: 100%;
  background-color: #f9f7f4;
  padding-top: 6vw;
  background-position-y: -3vw;
  padding-bottom: 4vw;
  background-repeat: no-repeat;
  overflow: hidden;
}
.cart {
  display: flex;
}
.cartLeft h2 {
  background: linear-gradient(277deg, #272c28 51.01%, #488255 110.27%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "NEXT ART";
  font-size: 9vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.cartLeft h3 {
  color: #272c28;
  display: flex;
  align-items: center;
  font-family: "NEXT ART";
  font-size: 9vw;
  font-style: normal;

  font-weight: 400;
  line-height: 8vw;
  text-transform: uppercase;
}
.cartLeft img {
  width: 10vw;
  margin-right: 1vw;
}
.cart {
  padding-left: 3vw;
}
.chast {
  color: #272c28;
  font-family: "NEXT ART";
  font-size: 2.4vw;
  font-style: normal;
  font-weight: 400;
  margin-top: 1vw;
  line-height: 150%; /* 72px */
  text-transform: uppercase;
}
.gr {
  color: #fff;
  font-family: "NEXT ART";
  font-size: 2.4vw;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 72px */
  text-transform: uppercase;
  border-radius: 100px;
  margin-top: 1vw;
  padding: 0.5vw 1vw;
  width: fit-content;
  background: linear-gradient(245deg, #c2f9ca -51.55%, #81bd8a 46.28%);
  box-shadow: 0px 7.07px 23.567px 0px rgba(106, 197, 127, 0.6);
}
.sea {
  color: #272c28;
  margin-top: 1.5vw;
  font-size: 1.4vw;
  font-style: normal;
  font-weight: 500;
  line-height: 200%; /* 52px */
}
.sea span {
  color: #488255;

  font-size: 1.6vw;
  font-style: normal;
  font-weight: 700;
  line-height: 200%;
  text-transform: uppercase;
}
.cartRight img {
  width: 18vw;
  margin-left: 12vw;
  margin-top: 7vw;
}
.thras {
  display: flex;
  margin-top: 6vw;
  padding: 0 3vw;
  align-items: flex-start;
}
.thrasCont h4 {
  background: linear-gradient(277deg, #272c28 51.01%, #488255 110.27%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: NEXT ART;
  font-size: 5.7vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  line-height: 5.7vw;
}
.thrasCont h4 img {
  width: 6vw;
  margin-right: 1vw;
}
.dinamic {
  color: #272c28;
  font-family: NEXT ART;
  font-size: 1.8vw;
  font-style: normal;
  font-weight: 400;
  margin: 1.5vw 0;
  line-height: 140%; /* 50.4px */
  text-transform: uppercase;
}
.car {
  margin-left: -1vw;
  margin-top: 1.5vw;
  width: 30.5vw;
}
.dinamic span {
  color: #488255;
}
.grayLine {
  width: 35vw;
  height: 1px;
  margin-bottom: 1.5vw;
  background: #488255;
}
.sochen {
  width: 31.5vw;
  color: #272c28;
  font-family: Montserrat;
  font-size: 1.1vw;
  font-style: normal;
  font-weight: 500;
  line-height: 200%; /* 40px */
}
.sochen span {
  color: #488255;
  font-weight: 700;
}
.stars p {
  color: #488255;
  text-align: right;
  font-family: NEXT ART;
  font-size: 2.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 48px */
  text-transform: uppercase;
}
.stars span {
  color: #488255;
  font-family: NEXT ART;
  font-size: 6.3vw;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  text-transform: uppercase;
}
.island {
  position: relative;
  display: block;
  right: -15.1vw;
  margin-top: 4vw;
  width: 84vw;
}
.stars div {
  color: #272727;
  text-align: right;
  font-family: Montserrat;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 500;
  line-height: 200%; /* 44.604px */
}
.stars img {
  width: 7.5vw;
}
.sevem {
  position: absolute;
  top: 10vw;
  left: 5vw;
  z-index: 5;
  width: 35vw;
}
.stars {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: -2vw;
  margin-left: 4vw;
  margin-right: 4vw;
}
.scop {
  width: 21vw;
  height: auto;
  margin-top: 5vw;
}

.anm {
  position: relative;
}
.obls {
  position: absolute;
  top: -5vw;
  left: 0;
  width: 100%;
  overflow-x: hidden;
  pointer-events: none;
}
.metks {
  position: absolute;
  top: 0;
  width: 60vw;
  top: 10vw;
  z-index: 5;
  left: 22vw;
}
.palmg {
  position: absolute;
  right: 0;
  bottom: -20vw;
  animation: rot 5s ease-in-out infinite;
}
.infpl img {
  width: 90vw;
  display: block;
  margin: 0 auto;
  margin-left: 6vw;
}
.infpl {
  position: relative;
  z-index: 5;
  pointer-events: none;
}
.ins {
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 5;
  font-family: NEXT ART;
  font-size: 2.5vw;
  margin-top: 6vw;
  margin-bottom: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: 128.063%; /* 61.47px */
  text-transform: uppercase;
  background: linear-gradient(277deg, #272c28 51.01%, #488255 110.27%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.secondBl .button {
  color: #fff;
  border-radius: 99px;
  background: linear-gradient(
      107deg,
      rgba(255, 255, 255, 0.1) 48.51%,
      rgba(255, 255, 255, 0) 49.21%
    ),
    #488255;
  box-shadow: 0px 15px 50px 0px rgba(93, 148, 105, 0.25);
  font-family: Montserrat;
  padding: 1.3vw 4vw;

  font-size: 1vw;
  width: fit-content;
  margin: 0 auto;
  font-style: normal;
  font-weight: 600;
  margin-top: -2vw;
  line-height: 151.023%; /* 30.205px */
}
@media (max-width: 640px) {
  .sea {
    color: #272c28;
    margin-top: 1.5vw;
    font-size: 4.4vw;
    font-style: normal;
    margin-top: 6vw;
    font-weight: 500;
    line-height: 120%;
  }
  .sea span {
    color: #488255;
    font-size: 4.4vw;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;
  }
  .thrasCont h4 img {
    width: 19vw;
    margin-right: 2vw;
  }
  .stars div {
    color: #272727;
    text-align: left;
    font-family: Montserrat;
    font-size: 4.2vw;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }
  .secondBl {
    background: url(./img/mobfon.png);
    background-size: 100%;
  }
  .secondBl {
    background: url(./img/mobfon.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position-y: -5vw;
    background-color: #f9f7f4;
    padding-top: 10vw;
    padding-bottom: 15vw;
  }
  .obls {
    pointer-events: none;
  }
  .secondBl .button {
    position: relative;
    z-index: 5;
    color: #fff;
    border-radius: 99px;
    background: linear-gradient(
        107deg,
        rgba(255, 255, 255, 0.1) 48.51%,
        rgba(255, 255, 255, 0) 49.21%
      ),
      #488255;
    box-shadow: 0px 15px 50px 0px rgba(93, 148, 105, 0.25);
    font-family: Montserrat;
    padding: 5.3vw 16vw;
    font-size: 4vw;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
    font-style: normal;
    font-weight: 600;
    margin-top: -11vw;
    line-height: 151.023%;
  }
  .infpl,
  .ins {
    z-index: 5;
    position: relative;
  }
  .infpl img {
    width: 100%;
    display: block;
    margin: 0 auto;
    margin-left: 0;
  }
  .ins {
    margin: 0 auto;
    text-align: center;
    font-family: NEXT ART;
    font-size: 9.5vw;
    margin-top: 63vw;
    margin-bottom: 1vw;
    font-style: normal;
    font-weight: 400;
    line-height: 128.063%;
    text-transform: uppercase;
    background: linear-gradient(277deg, #272c28 51.01%, #488255 110.27%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .island {
    position: relative;
    display: block;
    right: 0;
    margin-top: 19vw;
    width: 100vw;
  }
  .metks {
    position: absolute;
    top: 0;
    width: 71vw;
    top: 9vw;
    z-index: 5;
    left: 9vw;
  }
  .scop {
    width: 90vw;
    height: auto;
    margin: 0 auto;
    margin-top: 16vw;
  }
  .stars img {
    width: 28.5vw;
    margin-top: 3vw;
  }
  .stars span {
    color: #488255;
    font-family: NEXT ART;
    font-size: 14.3vw;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    text-transform: uppercase;
  }
  .stars p {
    color: #488255;
    text-align: right;
    font-family: NEXT ART;
    font-size: 6.5vw;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    text-transform: uppercase;
  }
  .car {
    margin-left: -1vw;
    margin-top: 5.5vw;
    width: 100%;
  }
  .stars {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 0vw;
    margin-left: 4vw;
    margin-right: 4vw;
  }
  .sochen {
    width: 100%;
    color: #272c28;
    font-family: Montserrat;
    font-size: 4.1vw;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }
  .dinamic {
    color: #272c28;
    font-family: NEXT ART;
    font-size: 6.8vw;
    font-style: normal;
    font-weight: 400;
    margin: 2.5vw 0;
    line-height: 140%;
    text-transform: uppercase;
  }
  .thrasCont h4 {
    background: linear-gradient(277deg, #272c28 51.01%, #488255 110.27%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: NEXT ART;
    font-size: 17.7vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    line-height: 16.7vw;
  }
  .palmg {
    display: none;
  }
  .thras {
    flex-direction: column;
  }
  .cartRight img {
    width: 48vw;
    margin-left: 21vw;
    margin-top: 7vw;
  }
  .cart {
    flex-direction: column;
  }
  .cartLeft h2 {
    background: linear-gradient(277deg, #272c28 51.01%, #488255 110.27%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "NEXT ART";
    font-size: 19vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }
  .secondpl {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    padding-bottom: 5vw;
  }
  .chast {
    color: #272c28;
    font-family: "NEXT ART";
    font-size: 3.5vw;
    font-style: normal;
    margin-top: 5vw;
    font-weight: 400;
    margin-top: 3vw;
    line-height: 150%;
    text-transform: uppercase;
  }
  .cartLeft h3 {
    color: #272c28;
    display: flex;
    align-items: center;
    font-family: "NEXT ART";
    font-size: 17vw;
    font-style: normal;
    font-weight: 400;
    line-height: 13vw;
    text-transform: uppercase;
  }
  .gr {
    color: #fff;
    font-family: "NEXT ART";
    font-size: 6.4vw;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-transform: uppercase;
    border-radius: 100px;
    margin-top: 0;
    padding: 1.5vw 5vw;
    width: -moz-fit-content;
    width: fit-content;
    background: linear-gradient(245deg, #c2f9ca -51.55%, #81bd8a 46.28%);
    box-shadow: 0px 7.07px 23.567px 0px rgba(106, 197, 127, 0.6);
  }
  .sevem {
    position: absolute;
    top: 25vw;
    left: 1vw;
    z-index: 5;
    width: 60vw;
  }
}

.Landing {
  overflow-x: hidden;
  width: 100%;
}
.Landing .rightFooter {
  flex-direction: column;
  align-items: flex-start;
}
.cartd {
  border-radius: 1vw;
  overflow: hidden;
}
.Landing .rightFooter h4 {
  margin: 0;
}

.headerTop {
  display: flex;
  align-items: center;
  padding-left: 5.2vw;
  padding-top: 1.4vw;
}
.logo {
  width: 5.5vw;
  margin-right: 4.3vw;
}
.headerTop p {
  font-size: 1.03vw;
  font-weight: 700;
  margin-right: 1vw;
  color: #fff;
}
.mess img {
  width: 2vw;
}
footer {
  margin-top: 3vw;
  padding-bottom: 2vw;
  padding-top: 3vw;
  background: #fbf3ed;
  margin-top: 4vw;
}
.leftFooter img {
  width: 50vw;
}
.footerContent {
  width: 92.5vw;
  margin-left: 5vw;
  display: flex;
}
.mess {
  margin-right: 0.8vw;
}
.nums {
  color: #fff;

  font-size: 1.4vw;
  font-style: normal;
  text-decoration: none;
  margin-left: 1.8vw;
  font-weight: 700;
}
.tabk {
  display: flex;
}
.tabk div {
  display: flex;
  align-items: center;
  border-radius: 6vw;
  background: #e0e5da;
  padding: 0.5vw;
  font-size: 1.6vw;
}
.kes {
  font-size: 1.3vw !important
  ;
}
.posadCont {
  margin-top: 0.5vw;
  margin-left: 5.2vw;
}
.tabk div img {
  width: 2.8vw;
}
.tabk div p {
  margin: 0 1.5vw;
}
.invest img {
  margin-left: 1vw;
}
.invest {
  background: linear-gradient(90deg, #4a975b 0%, #5db771 101.18%) !important;
  box-shadow: 0px 13px 20px 0px rgba(103, 194, 122, 0.56);
  color: #fff;
  font-weight: bold;
  margin-right: 2vw;
}
h1 {
  color: #fff;
  margin-top: 0.7vw;
  font-size: 3.8vw;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 102.287px */
  text-transform: uppercase;
}
.aqua {
  color: #fff;
  font-size: 6.7vw;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 150px */
  text-transform: uppercase;
}
h1 span {
  color: #9ed6aa;
}
h1 img {
  width: 2.7vw;
}
.leftPosad > p {
  color: #464d47;

  font-size: 1.25vw;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
.leftPosad > p span {
  color: #464d47;

  font-size: 1.3vw;
  font-style: normal;
  font-weight: 500;
}
.tablCont {
  display: flex;
  align-items: center;
  padding: 1vw;
  border-radius: 6vw;
  background: linear-gradient(
    90deg,
    #e9dada 0.05%,
    rgba(233, 218, 218, 0.3) 99.94%
  );
  width: fit-content;
  margin-top: 2vw;
}
.proc {
  border-radius: 6vw;
  background: linear-gradient(90deg, #877f7f 0%, #504d4d 101.18%);
  box-shadow: 0px 18.11265px 41.00045px 0px rgba(80, 87, 81, 0.56);
  color: #fff;

  font-size: 3vw;
  font-style: normal;
  font-weight: 700;
  padding: 0.5vw 1vw;
  margin-right: 1vw;
}
.tablCont p {
  color: #464d47;
  margin-right: 1vw;
  font-size: 1.37vw;
  font-style: normal;
}
.loska {
  display: flex;
  margin-top: 0.5vw;
}
.linePosad {
  background: #dbc9c9;
  height: 0.25vw;
  margin-right: 1.5vw;
  border-radius: 6vw;
  margin-top: 1vw;
  width: 6vw;
}
.loska b {
  color: #fff;

  font-size: 1.3vw;
  font-style: normal;
  font-weight: 500;
}
.loska p {
  font-size: 1.3vw;
  font-weight: 700;
  color: #fff;
  margin-top: 0.5vw;
  text-transform: uppercase;
}
.loska p span {
  color: #9dd6aa;
}
.buttonTime {
  display: flex;
  align-items: center;
  margin-top: 2.3vw;
}
.button:hover {
  filter: brightness(1.1);
}
.leftFooter {
  margin-right: 3vw;
}
.footerLogo {
  width: 5.5vw;
  margin-right: 2vw;
}
.phoneI a {
  color: #464d47;

  text-decoration: none;
  font-size: 2.3vw;
  font-style: normal;
  font-weight: 600;
}
.rightFooter p {
  color: #464d47;

  font-size: 1.3vw;
}
.rightFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.rightFooter h4 {
  color: #464d47;
  margin-left: 1vw;
  font-size: 1.7vw;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 600;
  margin-right: 0.5vw;
}
.messFooter {
  display: flex;
}
.messFooter img {
  width: 2.5vw;
  margin-right: 1vw;
}
.phoneI {
  display: flex;
  align-items: center;
}
.phoneI img {
  width: 2.3vw;
  margin-right: 1vw;
}
.posadCont .button {
  position: relative;
  overflow: hidden;
  border-radius: 6vw;
  color: #fff;
  margin-right: 2.5vw;
  padding: 2vw 4vw;
  font-size: 1.2vw;
  font-style: normal;
  background: linear-gradient(
      107deg,
      rgba(255, 255, 255, 0.1) 48.51%,
      rgba(255, 255, 255, 0) 49.21%
    ),
    #368d49;
  box-shadow: 0px 24.08418px 44.60034px 0px rgba(79, 153, 96, 0.4),
    0px 0px 26.7602px 0px rgba(118, 216, 140, 0.49) inset;
}
.politic {
  display: block;
  text-decoration: none;
  font-size: 1vw;
  color: #488255;
}
.povish {
  color: #fff;
  text-transform: uppercase;
  font-size: 1.55vw;
  font-style: normal;
  margin-right: 2vw;
  font-weight: 700;
  line-height: normal;
}
.tims {
  color: #488255;
  display: flex;
  font-size: 3vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 0.5vw;
}
.tims span {
  background: #fff;
  padding: 0 0.3vw;
  filter: drop-shadow(0px 8px 15px rgba(80, 52, 37, 0.35));
  border-radius: 0.5vw;
  display: block;
  width: 5vw;
  text-align: center;
  margin-right: 1vw;
  letter-spacing: 0.2vw;
}
.headTT {
  background: url(./img/fon.png);
  background-size: cover;
  padding-bottom: 7vw;

  position: relative;
  overflow: hidden;
}
.palm2 {
  position: absolute;
  left: -3.5vw;
  bottom: -14.5vw;
  width: 10vw;
}
.palm1 {
  position: absolute;
  top: 0vw;
  width: 20vw;
  right: 0vw;
  animation: rot 5s ease-in-out infinite;
  transform-origin: center;
}
.stone {
  position: absolute;
  right: 0;
  bottom: -3vw;
  width: 40vw;
}
.palm3 {
  position: absolute;
  right: 0;
  bottom: -25vw;
  z-index: 4;
  width: 20vw;
  animation: rot 5s ease-in-out infinite;
}
@keyframes rot {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(2deg) translateY(15px) scaleY(1.1);
  }

  100% {
    transform: rotate(0);
  }
}
@keyframes rot2 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px) scaleX(1.05) rotate(2deg);
  }

  100% {
    transform: translateY(0);
  }
}
.hor {
  display: flex;
}
.hor span {
  display: block;
  width: 5.8vw;

  color: #fff;

  font-size: 1.1vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.button {
  cursor: pointer;
}
.sendFrom .flare {
  position: absolute;
  top: 0;
  height: 100%;
  width: 45px;
  transform: skewX(-45deg);
  animation: flareAnimation;
  left: -150%;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.4)
  );
  animation: flareAnimation 5s infinite linear;
}
.button .flare {
  position: absolute;
  top: 0;
  height: 100%;
  width: 45px;
  transform: skewX(-45deg);
  animation: flareAnimation;
  left: -150%;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.4)
  );
  animation: flareAnimation 5s infinite linear;
}

@keyframes flareAnimation {
  0% {
    left: -200%;
  }
  100% {
    left: 150%;
  }
}
.posadCont {
  display: flex;
}
.rightPosad {
  position: relative;
}
.tab {
  position: absolute;
  left: -6vw;
  top: 3vw;
  width: 40.5vw;
}
.sdacha {
  position: absolute;
  top: 8vw;
  left: 54vw;
  width: 26vw;
}
.preim {
  display: flex;
  align-items: flex-start;
  margin-top: 1.5vw;
}
.preimEm {
  border-radius: 1.3vw;
  background: rgba(32, 33, 30, 0.3);
  box-shadow: 0px 4px 42.1px 0px rgba(106, 64, 64, 0.24);
  display: flex;
  align-items: center;
  color: #fff;
  padding: 0.7vw;
  padding-right: 1.5vw;
  margin-right: 3vw;
}
.preimEm img {
  width: 7vw;
  margin-right: 1.4vw;
}
.preimEm p {
  color: #fff;

  font-size: 2.5vw;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 0.5vw;
  line-height: 1.5vw;
}
.preimEm span {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  padding-top: 1vw;
  display: block;
  font-size: 1.3vw;
}
.preim > img {
  width: 9.5vw;
}
.preimEm p b {
  font-size: 1.2vw;
}
.book {
  position: absolute;
  z-index: 2;
  left: -1.5vw;
  top: -1.5vw;
  animation: book 4s ease-in-out infinite;
  width: 25vw;
}
.getf {
  position: absolute;
  z-index: 3;
  left: 10vw;
  width: 21vw;
  animation: txt 4s ease-in-out infinite;
  top: 5.5vw;
}
.day {
  margin-left: 0.7vw !important;
}
.cart {
  overflow: hidden;
  border-radius: 1vw;
}
@keyframes book {
  0% {
    transform: translate(0px);
  }
  50% {
    transform: translate(0, 5px);
  }
  100% {
    transform: translate(0px);
  }
}
@keyframes txt {
  0% {
    transform: translate(0px) scale(1);
  }
  50% {
    transform: translate(0, 5px) scale(1.05);
  }
  100% {
    transform: translate(0px) scale(1);
  }
}
@media (max-width: 640px) {
  .posadCont {
    flex-direction: column;
  }
  .headTT {
    overflow: visible;
    background: url(./img/mobFon.png);
    background-size: cover;
  }
  .messFooter a {
    margin: 0 2vw;
  }
  .politic {
    margin-top: 4vw;
    display: block;
    text-decoration: none;
    font-size: 4vw;
    color: #488255;
  }
  .kes {
    background: #e0e5da;
  }
  .leftFooter {
    margin-right: 0;
    margin-top: 8vw;
  }
  .leftFooter img {
    width: 100%;
  }
  .day {
    margin-left: 1.5vw !important;
    margin-right: 1.5vw !important;
  }
  .messFooter {
    margin-top: 3vw;
  }
  .messFooter a img {
    margin-right: 0;
    width: 10vw;
  }
  .tabk {
    flex-direction: column;
  }
  .logo {
    width: 14.5vw;
    margin-right: 15.3vw;
  }
  .mess img {
    width: 8vw;
  }
  .headTT {
    padding-bottom: 7vw;
  }
  .footerContent {
    flex-direction: column-reverse;
  }
  .rightFooter p {
    color: #464d47;
    margin-bottom: 2vw;
    margin-top: 2vw;
    font-size: 4vw;
  }
  .rightFooter h4 {
    color: #464d47;
    font-size: 4.7vw;
    text-transform: uppercase;
    margin-top: 4vw;
    font-style: normal;
    font-weight: 600;
  }
  .phoneI a {
    color: #464d47;
    text-decoration: none;
    font-size: 6.3vw;
    font-style: normal;
    font-weight: 600;
  }
  .phoneI img {
    width: 8.3vw;
    margin-right: 2vw;
  }
  .footerLogo {
    width: 17.5vw;
    margin-bottom: 6.5vw;
    margin-top: 7vw;
  }
  .rightFooter {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .nums {
    color: #fff;
    font-size: 4.4vw;
    font-style: normal;
    text-decoration: none;
    margin-left: 1.8vw;
    font-weight: 700;
  }
  .headerTop p {
    display: none;
  }
  .headerTop {
    padding: 3vw;
    justify-content: space-between;
    margin-bottom: 4vw;
  }
  .linePosad {
    display: none;
  }
  .loska {
    margin-top: 54vw;
  }
  .flexic {
    display: flex;
    margin-top: 5vw;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding-bottom: 15vw;
  }
  .povish {
    color: #fff;
    text-transform: uppercase;
    font-size: 5.55vw;
    font-style: normal;
    margin-right: 0;
    color: #fff;
    font-weight: 700;
    line-height: normal;
    margin-top: 3vw;
    margin-bottom: 5vw;
  }
  .tims {
    display: flex;
  }
  .tims span {
    background: #fff;
    display: block;
    width: 15vw;
    text-align: center;
    padding: 0 0.3vw;
    filter: drop-shadow(0px 8px 15px rgba(80, 52, 37, 0.35));
    border-radius: 1.5vw;
    margin-right: 2vw;
    letter-spacing: 0.2vw;
  }
  .hor {
    margin-top: 1vw;
    display: flex;
  }
  .hor span {
    width: 15vw;
    margin: 0 !important;
    margin-right: 2vw !important;
    text-align: center;
    color: #fff;
    font-size: 3.5vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .tims {
    color: #488255;
    font-size: 11vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
  }
  .footerContent {
    padding-bottom: 10vw;
  }
  .buttonTime {
    flex-direction: column;
  }
  .posadCont .button {
    position: relative;
    overflow: hidden;
    border-radius: 17vw;
    color: #fff;
    margin-right: 2.5vw;
    padding: 7vw 4vw;
    width: 90%;
    text-align: center;
    font-size: 5.2vw;
    font-style: normal;
    background: linear-gradient(
        107deg,
        rgba(255, 255, 255, 0.1) 48.51%,
        rgba(255, 255, 255, 0) 49.21%
      ),
      #368d49;
    box-shadow: 0px 24.08418px 44.60034px 0px rgba(79, 153, 96, 0.4),
      0px 0px 26.7602px 0px rgba(118, 216, 140, 0.49) inset;
  }
  .mobTab {
    width: 100vw;
    margin-left: -3vw;
    margin-top: -10vw;
    margin-bottom: 0;
  }
  .stone,
  .palm1,
  .palm2,
  .palm3 {
    display: none;
  }
  .loska img {
    width: 80vw;
    display: block;
    margin: 0 auto;
  }
  .loska b {
    color: #fff;
    font-size: 6.3vw;
    text-transform: uppercase;

    font-style: normal;
    font-weight: 700;
  }
  .loska b span {
    color: #fff;
    font-weight: 500;
    text-transform: lowercase;
  }
  .loska p {
    font-size: 6.7vw;
  }
  .sdacha {
    position: absolute;
    top: 220vw;
    left: 9vw;
    width: 85vw;
  }
  .tabk div,
  .kes {
    display: flex;
    align-items: center;
    border-radius: 6vw;
    width: fit-content;
    background: #e0e5da;
    padding: 1.5vw 3vw;
    font-size: 5.5vw;
  }
  .leftPosad > p {
    color: #464d47;
    font-size: 4vw;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    margin-top: 3vw;
    display: flex;
    flex-direction: column;
  }
  .leftPosad > p span {
    color: #464d47;
    font-size: 4.3vw;
    font-style: normal;
    font-weight: 500;
  }
  h1 img {
    width: 5.7vw;
  }
  .loskaCont * {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .loskaCont {
    width: 100%;
  }
  .mobs2 {
    width: 50vw;
    display: block;
    margin: 12vw auto;
  }
  .popuk {
    position: absolute;
    right: -3vw;
    width: 40vw;
    top: 112vw;
    animation: rot 8s infinite;
  }
  .tablCont {
    width: 100%;
    margin-top: 15vw;
    padding-top: 5vw;
    border-radius: 15vw;
    padding-bottom: 5vw;
    border-radius: 433.673px;
    background: linear-gradient(90deg, #eae1e1 0.05%, #fff 99.94%);
  }
  .tablCont p {
    color: #464d47;
    margin-right: 1vw;
    font-size: 4.37vw;
    text-align: center;
    font-style: normal;
  }
  .proc {
    border-radius: 10vw;
    background: linear-gradient(90deg, #877f7f 0%, #504d4d 101.18%);
    box-shadow: 0px 18.11265px 41.00045px 0px rgba(80, 87, 81, 0.56);
    color: #fff;
    font-size: 9vw;
    margin-top: -12vw;
    margin-bottom: 3vw;
    font-style: normal;
    font-weight: 700;
    padding: 1vw 3vw;
    margin-right: 1vw;
  }
  .mobs {
    width: 80vw;
    display: block;
    margin: 5vw auto;
    margin-bottom: 8vw;
  }
  .aqua {
    color: #fff;
    font-size: 12.7vw;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;
  }
  h1 {
    color: #fff;
    margin-top: 4vw;
    font-size: 7.45vw;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    line-height: 120%;
    text-transform: uppercase;
  }

  .tablCont {
    flex-direction: column;
  }
  .kes {
    font-size: 3.7vw !important;
    margin-top: 4vw;
    margin-bottom: 8vw;
    padding-left: 1.5vw !important;
  }
  .tabk div p {
    margin-left: 3vw;
  }
  .tabk div img,
  .kes img {
    width: 7.8vw;
  }
  .posadCont {
    margin-left: 0;
    padding: 0 3vw;
  }
}

.firstBl {
  background: #f9f7f4;
  padding-right: 3vw;
}
.firstBlContent {
  display: flex;
  padding-top: 2vw;
}
.rightFirstBl h3 {
  background: linear-gradient(98deg, #838997 -11.72%, #272c28 62.78%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "NEXT ART";
  font-size: 12vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.plyases {
  border-radius: 52px;
  background: #fff;
  box-shadow: 40px 40px 60px 0px rgba(8, 27, 15, 0.1);
  padding: 1vw;
  margin-top: 3vw;
}
.firstBlInvest {
  display: flex;
  margin-top: 1vw;
}
.firstPlyas {
  border-radius: 52px;
  border: 1px solid #fff;
  background: linear-gradient(
      238deg,
      rgba(255, 255, 255, 0.2) 2.58%,
      rgba(134, 192, 143, 0) 82.48%
    ),
    #81bd8a;
  width: 20vw;
  padding: 1vw;
  box-shadow: 0px 9px 30px 0px rgba(106, 197, 127, 0.6);
  padding-bottom: 2vw;

  margin-right: 1vw;
}
.arc {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 2vw;
}
.arc p {
  color: #272c28;

  font-family: "NEXT ART";
  font-size: 2.7vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.arc span {
  color: #272727;
  text-align: center;

  font-size: 0.8vw;
  font-style: normal;
  font-weight: 600;
  line-height: 180%; /* 30.353px */
}
.krugs {
  display: flex;
  align-items: flex-start;
  margin-left: -3vw;
  margin-top: 0.5vw;
}
.krugs p {
  margin-top: 5vw;
  color: #272727;

  font-size: 1.4vw;
  font-style: normal;
  font-weight: 600;
  line-height: 180%; /* 50.4px */
  width: 27vw;
}
.krugs span {
  color: #488255;
}
.grLine {
  width: 2.5vw;
  background: #488255;
  height: 4px;
  margin: 1vw 0;
}
.arc img {
  width: 2.5vw;
  margin-bottom: 0.5vw;
}
.secondpl {
  display: flex;
  align-items: center;
}
.plyases {
  display: flex;
  align-items: center;
}
.firstPlyas p {
  color: #fff;

  text-align: center;
  font-family: "NEXT ART";
  font-size: 4.5vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}
.firstPlyas span {
  color: #fff;
  text-align: center;

  display: block;
  font-size: 1vw;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 30px */
}
.firstBlInvest .greenLine {
  border-radius: 22px;
  background: #488255;
  width: 10vw;
  height: 0.5vw;
  margin-right: 1.5vw;
  margin-top: 0.5vw;
}
.firstBlInvest p {
  color: #272c28;

  font-size: 1.5vw;
  margin-right: 1vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.firstBlInvest img {
  width: 5vw;
  margin-top: -2.3vw;
}
.leftFirstBl img {
  width: 43vw;
  margin-right: 4vw;
}
.krugs img {
  width: 12.5vw;
  margin-top: 3vw;
}
.nextFbl {
  background: url(./img/fon.png);
  background-size: 100%;
  position: relative;
  background-position-y: -20.5vw;
  margin-top: 2vw;
}
.cif {
  position: absolute;
  right: 0;
  top: -6vw;
  width: 15vw;
}
.nextFbl h2 {
  font-family: "NEXT ART";
  font-size: 8vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  padding-left: 3vw;
}
.nextFbl h3 {
  display: flex;
  align-items: center;
  background: linear-gradient(98deg, #838997 -11.72%, #272c28 62.78%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "NEXT ART";
  font-size: 8vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.nextFbl .greenL {
  border-radius: 0px 36px 36px 0px;
  background: #488255;
  width: 20vw;
  height: 0.5vw;
  margin-right: 1vw;
}
.nextFbl > p {
  color: #272727;
  padding-left: 3vw;
  font-size: 1.3vw;
  width: 44vw;
  font-style: normal;
  font-weight: 500;
  line-height: 200%; /* 52px */
  margin-top: 3vw;
}
.krugles {
  padding-left: 1vw;
  margin-top: 2vw;
  width: 46vw;
}
.stats {
  display: flex;
  margin-top: 1vw;
}
.statsem {
  display: flex;
  align-items: center;
  padding: 0 3vw;
  border-right: 2px solid rgba(74, 91, 78, 0.15);
}
.statsem p {
  color: #488255;
  font-family: "NEXT ART";
  font-size: 2.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.statsem span {
  color: #272727;

  font-size: 1.3vw;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 37.5px */
}
.statsem img {
  width: 3vw;
  margin-right: 1vw;
}
.ceto {
  display: flex;
}
.popka {
  position: relative;
  top: 1.4vw;
  left: 7vw;
}
.txter {
  cursor: text;
}
.pop {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: -7vw;
  margin-left: 9vw;
}
.stats .statsem:last-child {
  border-right: none;
}
.prib {
  margin-left: 2vw;
  margin-top: 0vw;
  width: 64vw;
  cursor: text;
}
@media (max-width: 640px) {
  .firstBlContent {
    flex-direction: column-reverse;
  }
  .firstBl {
    padding-right: 0;
  }
  .prib {
    margin-left: 0;
    margin-top: 4vw;
    margin: 0 auto;
    display: block;
    width: 94vw;
    cursor: text;
  }
  .nextFbl {
    background: url(./img/fonmob.png);
    background-size: 100%;
    background-position-y: 3vw;
    width: 100%;
  }
  .krugs {
    flex-direction: column;
    margin: 0 auto;
    align-items: center;
  }
  .leftFirstBl img {
    width: 88vw;
    margin-right: 4vw;
  }
  .krugles {
    padding-left: 2vw;
    margin-top: 5vw;
    width: 94vw;
    margin-bottom: 5vw;
  }
  .stats {
    margin-bottom: 5vw;
  }
  .ceto {
    flex-direction: column;
  }
  .txter {
    cursor: text;
    width: 113%;
  }
  .popka {
    position: relative;
    top: 5.4vw;
    width: 33vw;
    left: 27vw;
  }
  .pop {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: -7vw;
    margin-left: 3vw;
  }
  .statsem span {
    color: #272727;
    font-size: 4.3vw;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }
  .statsem {
    margin-bottom: 7vw;
  }
  .statsem img {
    width: 9vw;
    margin-right: 3vw;
  }
  .statsem p {
    color: #488255;
    font-family: "NEXT ART";
    font-size: 7.5vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }
  .stats {
    flex-direction: column;
  }
  .nextFbl > p {
    color: #272727;
    padding-left: 3vw;
    font-size: 4vw;
    width: auto;
    font-style: normal;
    font-weight: 500;
    line-height: 200%;
    margin-top: 3vw;
  }
  .nextFbl h3 {
    display: flex;
    align-items: center;
    background: linear-gradient(98deg, #838997 -11.72%, #272c28 62.78%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "NEXT ART";
    font-size: 18vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }
  .nextFbl .greenL {
    border-radius: 0px 36px 36px 0px;
    background: #488255;
    width: 19vw;
    height: 0.8vw;
    margin-right: 4vw;
  }
  .nextFbl h2 {
    font-family: "NEXT ART";
    font-size: 15vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    padding-left: 3vw;
  }
  .cif {
    display: none;
  }
  .krugs p {
    margin-top: -10vw;
    color: #272727;
    font-size: 3.4vw;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 11vw;
    line-height: 180%;
    width: 75vw;
  }
  .krugs img {
    width: 43.5vw;
    margin-top: 3vw;
  }
  .rightFirstBl h3 {
    background: linear-gradient(98deg, #838997 -11.72%, #272c28 62.78%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "NEXT ART";
    font-size: 25vw;
    font-style: normal;
    font-weight: 400;
    margin-left: 1vw;
    line-height: normal;
    text-transform: uppercase;
  }
  .firstBlInvest p {
    color: #272c28;
    font-size: 4.5vw;
    margin-right: 1vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .plyases {
    flex-direction: column;
    border-radius: 6vw;
    margin-left: 3vw;
    margin-right: 3vw;
  }

  .cartLeft img {
    width: 16vw;
    margin-right: 2vw;
  }
  .cartLeft h3 {
    color: #272c28;
    display: flex;
    align-items: center;
    font-family: "NEXT ART";
    font-size: 18vw;
    font-style: normal;
    font-weight: 400;
    line-height: 14vw;
    text-transform: uppercase;
  }
  .firstPlyas p {
    color: #fff;
    text-align: center;
    font-family: "NEXT ART";
    font-size: 11.5vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }
  .firstPlyas {
    margin-bottom: 5vw;
  }
  .arc img {
    width: 9.5vw;
    margin-bottom: 1.5vw;
  }
  .secondpl {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    padding-bottom: 5vw;
  }
  .arc span {
    color: #272727;
    text-align: center;
    font-size: 3.8vw;
    font-style: normal;
    font-weight: 600;
    line-height: 180%;
  }
  .grLine {
    width: 10.5vw;
    background: #488255;
    height: 2px;
    margin: 3vw 0;
  }
  .arc p {
    color: #272c28;
    font-family: "NEXT ART";
    font-size: 9.7vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }
  .firstPlyas span {
    color: #fff;
    text-align: center;
    display: block;
    font-size: 4vw;
    padding-bottom: 2vw;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }
  .firstPlyas {
    border-radius: 4vw;
    border: 1px solid #fff;
    background: linear-gradient(
        238deg,
        rgba(255, 255, 255, 0.2) 2.58%,
        rgba(134, 192, 143, 0) 82.48%
      ),
      #81bd8a;
    width: 100%;
    padding: 3vw;
    box-shadow: 0px 9px 30px 0px rgba(106, 197, 127, 0.6);
    padding-bottom: 2vw;
    margin-right: 0;
  }
  .firstBlInvest .greenLine {
    border-radius: 22px;
    background: #488255;
    width: 12vw;
    height: 0.5vw;
    margin-left: 3vw;
    margin-right: 2.5vw;
    margin-top: 3.5vw;
  }
  .firstBlInvest img {
    width: 11vw;
    margin-top: -2.3vw;
  }
}

.thanks {
  background: url(./img/fon.png);
  background-size: cover;
}
.thanks h1 {
  font-size: 6vw;
}
.thanksCont {
  padding: 2vw 5vw;
  display: flex;
  justify-content: space-between;
  padding-bottom: 0;
}
.thanks .tabk {
  flex-direction: column;
  margin-top: 2vw;
}
.thanks .linePosad {
  width: 5vw;
}
.thanks .button {
  position: relative;
  overflow: hidden;
  border-radius: 6vw;
  color: #fff;
  margin-right: 0;
  padding: 2vw 6vw;
  font-size: 1.2vw;
  margin-bottom: 0.4vw;
  font-style: normal;
  background: linear-gradient(
      107deg,
      rgba(255, 255, 255, 0.1) 48.51%,
      rgba(255, 255, 255, 0) 49.21%
    ),
    #368d49;
  box-shadow: 0px 24.08418px 44.60034px 0px rgba(79, 153, 96, 0.4),
    0px 0px 26.7602px 0px rgba(118, 216, 140, 0.49) inset;
}
.flexics {
  display: flex;
  margin-top: 2vw;
  align-items: flex-start;
}
.flexics img {
  width: 9vw;
  margin-left: 2vw;
}
.flexics p {
  color: #464d47;

  text-align: center;
  font-size: 1vw;
  font-style: normal;
  font-weight: 500;
}
.thanks .loskaCont b {
  font-size: 1.5vw;
  margin-bottom: 0.5vw;
  display: block;
}
.thanks .loskaCont {
  text-transform: uppercase;
}
.thanks h1 {
  color: #464d47;
  font-weight: bold;
}
.thanks .loska b {
  color: #464d47;
}
.thanks .loska p {
  color: #464d47;
  font-weight: 500;
  text-transform: none;
}
.thanks .loska {
  margin-bottom: 3vw;
  margin-top: 0;
}
.thanks .loskaCont span {
  color: #488255;
}
.thanks .tabk div {
  width: fit-content;
  margin-bottom: 3vw;
  padding: 1vw;
}
.thanks .tabk div img {
  width: 3.5vw;
}
.thanks {
  position: relative;
  padding-bottom: 2vw;
}
.rightThanks img {
  width: 40vw;
  position: absolute;
  bottom: 0;
  right: 2vw;
  margin-top: 3vw;
  margin-left: -5vw;
  display: block;
}
.thanks .tabk .invest p {
  font-size: 2.3vw;
}
.thanks .kes {
  border-radius: 934.949px;
  background: linear-gradient(
    90deg,
    #e9dada 0.05%,
    rgba(233, 218, 218, 0.3) 99.94%
  );
}
@media (max-width: 640px) {
  .thanksCont {
    flex-direction: column;
  }
  .leftThanks {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .thanks .tabk div img {
    width: 6.5vw;
  }
  .thanks .tabk .invest p {
    font-size: 5.3vw;
  }
  .thanks .kes {
    flex-direction: column;
    text-align: center;
    padding: 4vw !important;
    border-radius: 8vw;
    margin-top: 10vw;
    background: linear-gradient(90deg, #f0ecec 0.05%, #fff 99.94%);
  }
  .rightThanks img {
    width: 80%;
    position: static;
    display: block;
    margin: 0 auto;
    margin-top: 5vw;
    margin-left: 15vw;
  }
  .thanks {
    background-image: url(./img/mobFon.png);
    padding-bottom: 5vw;
  }
  .thanks .loska p {
    text-align: center;
    font-size: 4.8vw;
    text-transform: lowercase;
  }
  .thanks .loskaCont b {
    font-size: 5.5vw;
    text-align: center;
    margin-top: 5vw;
    margin-bottom: 1.5vw;
    display: block;
  }
  .thanks .kes p {
    margin: 0 2vw;
  }
  .thanks .kes img {
    margin-top: -10vw;
    margin-bottom: 3vw;
    width: 12vw !important;
  }
  .thanks .logo {
    margin: 0;
    margin-top: 5vw;
  }
  .thanks h1 {
    font-size: 10.5vw;
  }
  .thanks .tabk {
    align-items: center;
  }
  .thanks .button {
    position: relative;
    overflow: hidden;
    border-radius: 11vw;
    width: 100%;
    margin: 0 auto;
    color: #fff;
    margin-right: 0;
    padding: 6vw 19vw;
    font-size: 5.2vw;
    margin-bottom: 0.4vw;
    font-style: normal;
    background: linear-gradient(
        107deg,
        rgba(255, 255, 255, 0.1) 48.51%,
        rgba(255, 255, 255, 0) 49.21%
      ),
      #368d49;
    box-shadow: 0px 24.08418px 44.60034px 0px rgba(79, 153, 96, 0.4),
      0px 0px 26.7602px 0px rgba(118, 216, 140, 0.49) inset;
  }
  .thanks .flexics {
    margin-top: 0;
    margin: 0 auto;
  }
  .thanks .flexics p {
    color: #464d47;
    text-align: center;
    color: #fff;
    margin-top: 2vw;
    font-size: 4vw;
    font-style: normal;
    font-weight: 400;
  }
}

.thirdBl {
  background: url(./img/fon.png);
  background-size: cover;
  padding-top: 8vw;
  background-color: #f9f7f4;
  padding-bottom: 5vw;
  position: relative;
  overflow: hidden;
}
.thirPalm {
  position: absolute;
  top: -1vw;
  width: 60vw;
  animation: rot 10s ease-in-out infinite;
  pointer-events: none;
}
.thContent {
  display: flex;
  padding-left: 3vw;
  position: relative;
}
.lik {
  position: absolute;
  top: 22vw;
  left: 48vw;
  display: block;
}
.thContent h3 {
  color: #fff;
  font-family: NEXT ART;
  font-size: 9vw;
  font-style: normal;
  font-weight: 400;
  z-index: 2;
  margin-top: 4vw;
  position: relative;
  line-height: normal;
  text-transform: uppercase;
}
.thContent h2 {
  background: linear-gradient(
    356deg,
    #bdf2c9 26.24%,
    rgba(17, 17, 17, 0.16) 102.16%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: NEXT ART;
  font-size: 3.3vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.rts {
  position: absolute;
  width: 45vw;
  right: 0;
}
.scthContent {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.thContent .grLine {
  background: #d8a6a6;
  margin: 3vw 0;
  width: 44vw;
  height: 1px;
}
.fourthRight {
  margin-top: 4vw;
}
.bork div {
  border-radius: 1vw;
  background: #2c2c2c;
  display: flex;
  padding-right: 3vw;
  align-items: center;
  box-shadow: 0px 7px 30px 0px rgba(7, 7, 7, 0.43);
}
.scthContent > img {
  width: 49.5vw;
}
.bork {
  margin-right: 4vw;
}
.bork p {
  text-align: center;
  color: #fff;
  color: #eadddd;
  text-align: center;
  font-family: Montserrat;
  font-size: 1.2vw;
  width: 16.5vw;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 33px */
}
.getVideo {
  display: flex;
  align-items: center;
  padding: 4vw 0;
  padding-top: 3vw;
  justify-content: center;
}
.getVideo p {
  color: #fff;
  font-family: NEXT ART;
  font-size: 2.4vw;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 69px */
  text-transform: uppercase;
}
.getVideo .button {
  border-radius: 99px;
  background: linear-gradient(
      107deg,
      rgba(255, 255, 255, 0.1) 48.51%,
      rgba(255, 255, 255, 0) 49.21%
    ),
    #488255;
  box-shadow: 0px 15px 50px 0px rgba(93, 148, 105, 0.25);
  color: #fff;
  font-family: Montserrat;
  font-size: 1.3vw;
  padding: 1vw 4vw;
  font-style: normal;
  font-weight: 600;
  line-height: 151.023%;
  margin-left: 3vw;
}

.bork div {
  margin-bottom: 2vw;
}
.qka {
  width: 44vw;
  background: linear-gradient(
    270deg,
    #e8d5d5 0.06%,
    rgba(195, 177, 177, 0.6) 101.59%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Montserrat;
  font-size: 1.7vw;
  font-style: normal;
  font-weight: 500;
  line-height: 180%;
  padding-bottom: 10vw;
}
.bork img {
  width: 23.5vw;
}
.ownik {
  width: 95%;
  margin-left: 2.5vw;
}
.qka b {
  font-weight: 500;
  color: #9dd6a9;
  background: unset;
  background-clip: unset;
  -webkit-text-fill-color: #9dd6a9;
  -webkit-background-clip: unset;
}
.qka span {
  -webkit-text-fill-color: #fff;
}
@media (max-width: 640px) {
  .thirdBl {
    background: url(./img/mobFon.png);
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 20vw;
  }
  .thContent {
    padding-right: 3vw;
  }
  .looker {
    transition: 0.5s;
  }
  .looker {
    display: grid;
    margin-top: 5vw;
    grid-template-columns: 100vw 100vw 100vw 100vw 100vw 100vw 100vw 100vw;
  }
  .looker img {
    width: 100vw;
  }
  .thContent h2 {
    background: linear-gradient(
      356deg,
      #bdf2c9 26.24%,
      rgba(17, 17, 17, 0.16) 102.16%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: NEXT ART;
    font-size: 8.3vw;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }
  .thContent h3 {
    color: #fff;
    font-family: NEXT ART;
    font-size: 14vw;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    z-index: 2;
    margin-top: 4vw;
    position: relative;
    line-height: normal;
    text-transform: uppercase;
  }
  .thContent .grLine {
    display: none;
  }
  .scthContent {
    flex-direction: column;
  }
  .getVideo p {
    color: #fff;
    font-family: NEXT ART;
    font-size: 5.4vw;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-transform: uppercase;
  }
  .getVideo .button {
    border-radius: 99px;
    background: linear-gradient(
        107deg,
        rgba(255, 255, 255, 0.1) 48.51%,
        rgba(255, 255, 255, 0) 49.21%
      ),
      #488255;
    box-shadow: 0px 15px 50px 0px rgba(93, 148, 105, 0.25);
    color: #fff;
    font-family: Montserrat;
    font-size: 4.3vw;
    padding: 4vw 17vw;
    font-style: normal;
    font-weight: 600;
    line-height: 151.023%;
    margin-left: 0;
    margin-top: 4vw;
  }
  .getVideo {
    flex-direction: column;
  }
  .bork p {
    text-align: center;
    color: #fff;
    color: #eadddd;
    padding: 5vw 0;
    text-align: center;
    font-family: Montserrat;
    font-size: 4.2vw;
    width: 91%;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }
  .scthContent > img {
    width: 99vw;
    margin-top: 8vw;
  }
  .bork div {
    flex-direction: column;
    padding-right: 0;
    padding-left: 4vw;
    border-radius: 6vw;
    padding-top: 4vw;
    margin-bottom: 6vw;
  }
  .bork img {
    width: 88vw;
  }

  .bork {
    margin: 0 auto;
    margin-top: 7vw;
    display: none;
  }
  .qka {
    width: 100%;
    background: linear-gradient(
      270deg,
      #e8d5d5 0.06%,
      rgba(195, 177, 177, 0.6) 101.59%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Montserrat;
    font-size: 4.7vw;
    font-style: normal;
    margin-top: 6vw;
    font-weight: 500;
    line-height: 180%;
    padding-bottom: 10vw;
  }
  .lik {
    display: none;
  }
  .thContent {
    flex-direction: column;
  }
  .rts {
    position: static;
    width: 97vw;
    right: 0;
  }
}

.botTitle {
  color: #272c28;

  font-size: 3vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
h2 {
  color: #272c28;

  font-size: 7vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  letter-spacing: -3px;
}
ul {
  list-style-type: none;
}
.bot ul li div {
  color: #fff;

  width: 3vw;
  height: 3vw;
  background: linear-gradient(
      180deg,
      rgba(227, 255, 167, 0.2) 0%,
      rgba(227, 255, 167, 0) 100%
    ),
    #7ab787;
  stroke-width: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  stroke: #fff;
  box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.25) inset;
  filter: drop-shadow(0px 20px 25px rgba(114, 157, 104, 0.4));
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 180%;
}
.subs {
  display: flex;
  align-items: center;
}
.nos {
  display: flex;
  align-items: center;
}
.button {
  position: relative;
  overflow: hidden;
}
.nos p {
  color: rgba(39, 44, 40, 0.6);

  font-size: 1.3vw;
  font-style: normal;
  font-weight: 600;
}
.nos img {
  width: 2vw;
  margin-right: 1vw;
  margin-left: 2vw;
}
.bot ul {
  margin-top: 3vw;
}
.bot ul li {
  display: flex;
  align-items: center;
  padding: 1.5vw 0;
  border-bottom: 1px solid rgba(134, 174, 139, 0.3);
}
.bot ul li:last-child {
  border-bottom: none;
}
.palmBot {
  position: absolute;
  top: -1vw;
  width: 37vw;
  left: -3vw;
  animation: rot 10s ease-in-out infinite;
}
.botPic {
  position: absolute;
  top: 10vw;
  width: 30vw;
  animation: book 6s infinite;
}
.bot ul li p {
  color: #272c28;

  font-size: 1.5vw;
  margin-left: 1.5vw;
  font-style: normal;
  font-weight: 600;
}
@keyframes rot {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(4deg) translateY(10px) scaleY(1.1);
  }

  100% {
    transform: rotate(0);
  }
}
h3 {
  color: #272c28;

  font-size: 11vw;
  font-style: normal;
  font-weight: 400;
  line-height: 10vw;
  text-transform: uppercase;
}
h3 img {
  width: 13vw;
  margin-right: 1vw;
}
.fonic {
  width: 40vw;
}
h2 img {
  width: 5vw;
}
.botContent {
  width: 94.11vw;
  display: flex;
  margin-left: 5vw;
  justify-content: space-between;
}
.rightBot {
  padding-top: 15vw;
  position: relative;
}
.bot .button {
  border-radius: 5vw;
  background: linear-gradient(
      107deg,
      rgba(255, 255, 255, 0.1) 48.51%,
      rgba(255, 255, 255, 0) 49.21%
    ),
    #488255;
  box-shadow: 0px 15px 50px 0px rgba(93, 148, 105, 0.25);
  padding: 1.8vw 3vw;
  color: #fff;

  font-size: 1vw;
  font-style: normal;
  font-weight: 600;
}
.subs {
  margin-top: 3vw;
}
@media (max-width: 640px) {
  .botContent {
    flex-direction: column;
    margin-left: 0;
    width: 94vw;
    margin: 0 auto;
  }
  .botTitle {
    color: #272c28;
    font-size: 5vw;
    font-style: normal;
    font-weight: 400;
    margin-top: 10vw;
    line-height: normal;
    text-transform: uppercase;
  }
  h2 {
    color: #272c28;
    font-size: 13vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    letter-spacing: -3px;
  }
  h2 img {
    width: 10vw;
  }
  h3 {
    color: #272c28;
    font-size: 19vw;
    font-style: normal;
    font-weight: 400;
    display: flex;
    align-items: center;
    line-height: 18vw;
    text-transform: uppercase;
  }
  h3 img {
    width: 26vw;
    margin-right: 2vw;
  }
  .bot ul li p {
    color: #272c28;
    font-size: 4vw;
    margin-left: 4.5vw;
    font-style: normal;
    font-weight: 600;
  }
  .bot ul li div {
    width: 9vw;
    font-size: 5vw;
    height: 9vw;
    min-width: 9vw;
  }
  .bot ul li {
    padding: 4vw 0;
  }
  .bot ul {
    margin-top: 6vw;
  }
  .subs {
    flex-direction: column;
  }
  .bot .button {
    border-radius: 10vw;
    background: linear-gradient(
        107deg,
        rgba(255, 255, 255, 0.1) 48.51%,
        rgba(255, 255, 255, 0) 49.21%
      ),
      #368d49;

    box-shadow: 0px 18.19328px 33.69125px 0px rgba(79, 153, 96, 0.4),
      0px 0px 20.21475px 0px rgba(118, 216, 140, 0.49) inset;
    padding: 7vw 8vw;
    color: #fff;
    font-size: 5vw;
    font-style: normal;
    font-weight: 600;
  }
  .nos {
    flex-direction: column;
    margin-top: 4svw;
  }
  .botPic {
    position: absolute;
    top: 3vw;
    width: 76vw;
    animation: book 6s infinite;
  }
  .rightBot {
    padding-top: 15vw;
    position: relative;
    margin-top: 15vw;
    margin-bottom: 10vw;
  }
  .palmBot {
    position: absolute;
    top: -19vw;
    width: 77vw;
    left: -7vw;
    animation: rot 10s ease-in-out infinite;
  }
  .fonic {
    width: 97vw;
    margin-bottom: 10vw;
  }
  .nos img {
    width: 7vw;
    margin-right: 0;
    margin-left: 0;
    transform: rotate(90deg);
  }
  .nos p {
    color: rgba(39, 44, 40, 0.6);
    font-size: 4vw;
    margin-top: 2vw;
    font-style: normal;
    font-weight: 600;
  }
}

.fourthBl {
  background: url(./img/fon.png);
  background-size: 100%;
  margin-top: -4vw;
  background-repeat: no-repeat;
  background-color: #f9f7f4;
  position: relative;
}
.fourthContent {
  display: flex;
  justify-content: space-between;
  padding: 6vw 3vw;
}
.fourthLeft h2 {
  color: #272c28;
  font-family: NEXT ART;
  font-size: 10vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.fourthLeft h3 {
  color: #272c28;
  font-family: NEXT ART;
  font-size: 3.1vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 2vw;
}
.photki {
  width: 100%;
  padding: 0 3vw;
  display: flex;

  justify-content: space-between;
  align-items: flex-end;
}
.photki img:first-child {
  width: 36vw;
}
.photki img {
  width: 16vw;
}
.tipes {
  width: 100%;
  padding: 0 3vw;
  display: flex;
  margin-top: 5vw;
  justify-content: space-between;
  margin-bottom: 3vw;
}
.tipes img {
  height: 29vw;
}
.noutCont h3 {
  color: #488255;
  font-family: NEXT ART;
  font-size: 5vw;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 96px */
  text-transform: uppercase;
}
.noutCont h2 {
  background: linear-gradient(274deg, #272c28 62.85%, #488255 97.07%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: NEXT ART;
  font-size: 9vw;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 180px */
  text-transform: uppercase;
}
.noutCont > p {
  color: #272c28;
  font-family: NEXT ART;
  font-size: 2.5vw;
  font-style: normal;
  margin-top: 1vw;
  font-weight: 400;
  line-height: 150%; /* 72px */
  text-transform: uppercase;
}
.noutCont > div {
  background: #fff;
  position: relative;
  padding: 1.2vw;
  border-radius: 1vw;
  display: flex;
  margin-top: 2vw;
  padding-left: 3vw;
  align-items: center;
}
.noutCont div {
  margin-left: 2vw;
}
.noutImg {
  width: 59vw;
}
.noutCont .button {
  border-radius: 99px;
  padding: 1.5vw 6vw;
  margin-left: 0;
  color: #fff;
  margin-top: 3vw;
  text-align: right;
  font-family: Montserrat;
  font-size: 1.3vw;
  width: fit-content;
  font-style: normal;
  font-weight: 600;
  line-height: 151.023%; /* 36.245px */
  background: linear-gradient(
      107deg,
      rgba(255, 255, 255, 0.1) 48.51%,
      rgba(255, 255, 255, 0) 49.21%
    ),
    #488255;
  box-shadow: 0px 15px 50px 0px rgba(93, 148, 105, 0.25);
}
.noutCont div img {
  position: relative;

  left: -5vw;
  width: 4vw;
}
.noutCont div p b {
  color: #488255;
}
.noutCont div p {
  margin-left: -3vw;
  color: #272c28;
  font-family: Montserrat;
  font-size: 1.6vw;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
.noutEm {
  padding-right: 3vw;
  background: url(./img/fonic.png);
  background-size: 100%;
  background-repeat: no-repeat;
  margin-top: 0vw;
  padding-top: 8vw;
  padding-bottom: 6vw;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.fourthContent li {
  display: flex;
  align-items: center;
  width: 35vw;
  color: #272c28;
  font-family: Montserrat;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 39px */
  padding: 1.5vw 0;
  border-bottom: 2px solid rgba(72, 130, 85, 0.16);
}
.fourthContent li img {
  width: 3.5vw;
  margin-right: 1.5vw;
}
.friends {
  display: flex;
}
.friends div {
  display: flex;
  flex-direction: column;
  background: #fff;
  align-items: center;
  position: relative;
  padding: 1.5vw 2vw;
  margin: 3vw 2vw;
  width: 20vw;
  padding-top: 4vw;

  background: #fff;
  box-shadow: 31.423px 31.423px 47.134px 0px rgba(8, 27, 15, 0.1);
  border-radius: 2vw;
}
.friends p {
  color: #272c28;
  text-align: center;
  font-family: NEXT ART;
  font-size: 7vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.friends img {
  position: absolute;
  top: -4.5vw;
  width: 10vw;
}
.yer {
  border-radius: 1vw;
  padding: 1vw 2vw;
  width: 44vw;
  margin-left: 2vw;
  background: linear-gradient(
      238deg,
      rgba(160, 132, 132, 0.2) 2.58%,
      rgba(94, 94, 94, 0) 82.48%
    ),
    #2c2c2c;
  color: #fff;
  text-align: center;
  font-family: NEXT ART;
  font-size: 2vw;
  font-style: normal;

  line-height: 150%; /* 60px */
  text-transform: uppercase;
  box-shadow: 0px 20px 30px 0px rgba(34, 25, 18, 0.35);
}
.friends span {
  color: #272c28;
  text-align: center;
  font-family: NEXT ART;
  font-size: 1.2vw;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 36px */
  text-transform: uppercase;
}
.realInv {
  padding: 5vw 3vw;
  position: relative;
  background: url(./img/fonReal.png);
  background-size: 100%;
  padding-bottom: 7.7vw;
}
.fonicPalm {
  position: absolute;
  left: -1vw;
  top: -2vw;
  width: 12vw;
  animation: rot 10s ease-in-out infinite;
}
.almaz img {
  animation: rot2 5s infinite;
}
.realInv h3 {
  text-align: center;
  font-family: NEXT ART;
  font-size: 6.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  background: linear-gradient(274deg, #272c28 62.85%, #488255 97.07%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.realInv h4 {
  color: #636f66;
  font-family: NEXT ART;
  font-size: 2vw;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  margin-top: 1.5vw;
  line-height: normal;
  text-transform: uppercase;
}
.realInv h4 b {
  color: #488255;
}
.realCont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 4vw;
  padding: 0 2vw;
}
.realContLeft {
  border-radius: 1.3vw;
  color: #fff;
  font-family: Montserrat;
  font-size: 1.6vw;
  padding: 1vw 1.5vw;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 48px */
  background: linear-gradient(250deg, #c2f9ca -97.3%, #81bd8a 61.06%);
  box-shadow: 0px 7.07px 23.567px 0px rgba(106, 197, 127, 0.6);
}
.realContRight {
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 20vw 6vw 6vw 20vw;
}
.realContRight p {
  color: #272c28;
  font-family: Montserrat;
  padding: 0 2vw;
  font-size: 1.6vw;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 48px */
}
.realContRight img {
  width: 10.5vw;
}
.plans {
  display: flex;
  margin-top: 3vw;
  align-items: center;
  justify-content: center;
  font-size: 1.9vw;
  font-style: normal;

  line-height: normal;
  text-transform: uppercase;
}
.plans div b {
  color: #488255;
}
.plans div {
  font-weight: 700;
  color: #636f66;
}
.greenL {
  background: #7ab787;
  width: 6vw;
  margin: 0 1vw;
  height: 2px;
}
.almaz {
  display: flex;
  align-items: center;
  justify-content: center;
}
.almaz img {
  width: 20.1vw;
}
.almaz p {
  color: #636f66;
  text-align: center;
  font-family: Montserrat;
  font-size: 1.6vw;
  font-style: normal;
  border-radius: 1.3vw;
  padding: 1.6vw 3vw;
  font-weight: 500;
  line-height: 150%; /* 48px */
  border: 2px solid #c2f9ca;
  background: #f0f6f0;
  box-shadow: 0px 4px 60px 0px rgba(77, 139, 91, 0.25);
}
.almaz span {
  color: #7ab787;
  font-weight: 700;
}
@media (max-width: 640px) {
  .fourthBl {
    background: url(./img/mobFon.png);
    background-size: 100%;
    background-repeat: no-repeat;
    margin-top: -9vw;
  }
  .friends {
    margin-bottom: 5vw;
  }
  .fourthContent {
    flex-direction: column;
  }
  .fourthLeft h3 {
    color: #272c28;
    font-family: NEXT ART;
    font-size: 9.1vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 5vw;
  }
  .fourthContent li img {
    width: 9.5vw;
    margin-right: 4.5vw;
  }
  .friends img {
    position: absolute;
    top: -4.5vw;
    width: 21vw;
  }
  .friends span {
    color: #272c28;
    text-align: center;
    font-family: NEXT ART;
    font-size: 3vw;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-transform: uppercase;
  }
  .friends p {
    color: #272c28;
    text-align: center;
    font-family: NEXT ART;
    font-size: 14vw;
    margin: 2vw 0;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }
  .fourthRight {
    margin-top: 18vw !important;
  }
  .yer {
    border-radius: 3vw;
    padding: 3vw 2vw;
    margin-top: 6vw;
    width: 96%;
    margin-left: 2vw;
    background: linear-gradient(
        238deg,
        rgba(160, 132, 132, 0.2) 2.58%,
        rgba(94, 94, 94, 0) 82.48%
      ),
      #2c2c2c;
    color: #fff;
    text-align: center;
    font-family: NEXT ART;
    font-size: 6vw;
    font-style: normal;
    line-height: 150%;
    text-transform: uppercase;
    box-shadow: 0px 20px 30px 0px rgba(34, 25, 18, 0.35);
  }
  .friends div {
    display: flex;
    flex-direction: column;
    background: #fff;
    align-items: center;
    position: relative;
    padding: 3vw 2vw;
    margin: 3vw 2vw;
    width: 44vw;
    padding-bottom: 5vw;
    padding-top: 12vw;
    background: #fff;
    box-shadow: 31.423px 31.423px 47.134px 0px rgba(8, 27, 15, 0.1);
    border-radius: 4vw;
  }
  .noutCont h2 {
    background: linear-gradient(274deg, #272c28 62.85%, #488255 97.07%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: NEXT ART;
    font-size: 22vw;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    text-transform: uppercase;
  }
  .noutCont .button {
    border-radius: 99px;
    padding: 4.5vw 20vw;
    text-align: center;
    margin-left: 0;
    color: #fff;
    margin-top: 3vw;
    text-align: right;
    font-family: Montserrat;
    font-size: 4.3vw;
    width: -moz-fit-content;
    width: fit-content;
    font-style: normal;
    margin: 0 auto;
    margin-top: 6vw;
    margin-bottom: 10vw;
    font-weight: 600;
    line-height: 151.023%;
    background: linear-gradient(
        107deg,
        rgba(255, 255, 255, 0.1) 48.51%,
        rgba(255, 255, 255, 0) 49.21%
      ),
      #488255;
    box-shadow: 0px 15px 50px 0px rgba(93, 148, 105, 0.25);
  }
  .noutImg {
    width: 94vw;
  }
  .noutCont div {
    background: #fff;
    position: relative;
    padding: 3.2vw;
    border-radius: 3vw;
    display: flex;
    margin-top: 2vw;
    padding-left: 3vw;
    align-items: center;
  }
  .noutCont div img {
    position: relative;
    left: -5vw;
    width: 12vw;
  }
  .noutCont div p {
    margin-left: -3vw;
    color: #272c28;
    font-family: Montserrat;
    font-size: 4.6vw;
    font-style: normal;
    font-weight: 500;
    line-height: 138%;
  }
  .noutCont > p {
    color: #272c28;
    font-family: NEXT ART;
    font-size: 6.5vw;
    font-style: normal;
    margin-top: 1vw;
    font-weight: 400;
    line-height: 150%;
    text-transform: uppercase;
  }
  .photki img:first-child {
    width: 94vw;
  }
  .sliderBut {
    display: flex;
    justify-content: center;
    margin: 4vw 0;
    width: 100%;
    padding-right: 3vw;
  }
  .sliderBut div {
    width: 5vw;
    height: 5vw;
    background: #cdc2c2;
    border-radius: 50%;
    margin: 2vw;
    transition: 0.5s;
  }
  .photki img {
    width: 94vw;
  }
  .photki {
    width: 100%;
    padding: 0 3vw;
    transition: 0.5s;
    margin-top: 28vw;
    display: grid;
    /* justify-content: space-between; */
    grid-template-columns: 100vw 100vw 100vw 100vw;
    /* align-items: flex-end; */
  }
  .tipes {
    width: 100%;
    padding: 0;
    display: flex;
    margin-top: 5vw;
    justify-content: space-between;
    margin-bottom: 0vw !important;
  }
  .noutCont h3 {
    color: #488255;
    font-family: NEXT ART;
    font-size: 13vw;
    margin-top: 5vw;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    text-transform: uppercase;
  }
  .noutEm {
    background: url(./img/mobFonic.png);
    background-repeat: no-repeat;
    background-size: 100%;
    flex-direction: column-reverse;
    padding: 10vw 3vw;
  }
  .tipes img {
    height: auto;
    width: 100vw;
  }
  .fourthContent li {
    display: flex;
    align-items: center;
    width: 100%;
    color: #272c28;
    font-family: Montserrat;
    font-size: 4.3vw;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    padding: 5vw 0;
    border-bottom: 2px solid rgba(72, 130, 85, 0.16);
  }
  .fourthLeft h2 {
    color: #272c28;
    font-family: NEXT ART;
    font-size: 26vw;
    margin-top: 10vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }
  .realInv {
    background: url(./img/mobFonreal.png);
    background-repeat: no-repeat;
    background-size: 100%;
    padding-bottom: 15.7vw;
  }
  .realContRight {
    flex-direction: column;
    border-radius: 6vw;
    margin-top: 20vw;
    padding-bottom: 6vw;
  }
  .realContRight img {
    width: 21.5vw;
    margin-top: -12vw;
  }
  .plans {
    flex-direction: column;
    align-items: center;
  }
  .almaz img {
    width: 34.1vw;
    display: none;
  }
  .almaz p {
    color: #636f66;
    text-align: center;
    font-family: Montserrat;
    font-size: 4.6vw;
    font-style: normal;
    border-radius: 3.3vw;
    padding: 3.6vw 3vw;
    margin-top: 11vw;
    font-weight: 500;
    line-height: 150%;
    border: 2px solid #c2f9ca;
    background: #f0f6f0;
    box-shadow: 0px 4px 60px 0px rgba(77, 139, 91, 0.25);
  }
  .kukla {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .greenL {
    background: #7ab787;
    width: 33vw;
    margin: 5vw 2vw;
    height: 2px;
  }
  .plans {
    display: flex;
    margin-top: 8vw;
    align-items: center;
    justify-content: center;
    font-size: 4.9vw;
    font-style: normal;
    line-height: normal;
    text-transform: uppercase;
  }
  .realContRight p {
    color: #272c28;
    font-family: Montserrat;
    padding: 0 2vw;
    font-size: 4.4vw;
    padding: 0 5vw;
    margin-top: 3vw;
    font-style: normal;
    text-align: center;
    font-weight: 500;
    line-height: 150%;
  }
  .realContLeft {
    border-radius: 4.3vw;
    color: #fff;
    font-family: Montserrat;
    font-size: 5vw;
    text-align: center;
    padding: 4vw 7.5vw;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    background: linear-gradient(250deg, #c2f9ca -97.3%, #81bd8a 61.06%);
    box-shadow: 0px 7.07px 23.567px 0px rgba(106, 197, 127, 0.6);
  }
  .realInv h4 {
    color: #636f66;
    font-family: NEXT ART;
    font-size: 6vw;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    margin-top: 3.5vw;
    line-height: normal;
    text-transform: uppercase;
  }
  .realCont {
    flex-direction: column;
  }
  .realInv h3 {
    text-align: center;
    font-family: NEXT ART;
    font-size: 9.4vw;
    font-style: normal;
    font-weight: 400;
    margin: 0 auto;
    text-align: center;
    line-height: normal;
    text-transform: uppercase;
    background: linear-gradient(274deg, #272c28 62.85%, #488255 97.07%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
